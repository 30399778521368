.b-relation {
    .b-relation-colonne_wrap {
        display: flex;
        flex-direction: column;
        height: 100%;
        .c-card {
            &.-post {
                &:first-of-type {
                    border-top-left-radius: rem(15px);
                    border-top-right-radius: rem(15px);
                }
                &:last-of-type {
                    border-bottom-left-radius: rem(15px);
                    border-bottom-right-radius: rem(15px);
                    border-bottom: none;
                }
            }
        }

        .fb_iframe_widget {
            border-radius: rem(15px);
            overflow: hidden;
        }
    }
    .b-relation-colonne_action {
        margin-top: auto;
    }

    .pagination {
        .-prev {
            svg {
                transform: rotate(180deg);
            }
        }
    }
}

.b-relation-pages-switch {
    position: relative;
    z-index: 2;
    .deco {
        svg {
            width: 100%;
        }
    }
    // .deco {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     right: 0;
    //     bottom: 0;
    //     svg {
    //         height: 110%;
    //     }
    // }
    .c-tab .c-tab_body {
        background-color: transparent;
    }
    // .c-tab_body {
    //     overflow: hidden;
    //     position: relative;
    //     &.-bg {
    //         &::before {
    //             content: "";
    //             position: absolute;
    //             width: 300vw;
    //             height: 285vw;
    //             bottom: 0;
    //             left: 50%;
    //             transform: translateX(-50%);
    //             background-color: $white;
    //             z-index: -1;
    //             border-radius: calc(300vw / 2);
    //         }
    //     }
    // }
    .c-tab {
        // display: flex;
        // flex-direction: column;
        // align-items: center;
        text-align: center;
        .c-tab_head {
            background-color: $white;
            border-radius: rem(45px);
            padding: 0.5rem;
            box-shadow: 0 rem(10px) rem(30px) rgba(44, 77, 62, 0.2);
            height: rem(88px);
            display: inline-flex;
            
            min-width: rem(450px);
            @media (max-width: $to-small) {
                min-width: rem(280px);
              }
            &.-negative {
                transform: translateY(-50%);
            }
            .c-tab_item {
                padding: 1rem 1.5rem;
                border-radius: rem(45px);
                color: $primary-dark;
                font-weight: $medium;
                flex: 1 1 0;
                text-align: center;
                transition: $speed $easing;
                cursor: pointer;
                display: flex;
                align-items: center;
                justify-content: center;
                &:hover {
                    background-color: $grey-light2;
                }
                &.is-active {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
        .c-tab_body {
            // .c-tab_item, .c-slider {
            //     min-width: 100%;
            // }
            // .swiper-slide {
            //     max-width: calc(100vw - #{rem($unit)});
            // }
        }
    }
    .c-slider {
        position: relative;
        .swiper-navigation_button {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            width: 50px;
            height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $second;
            z-index: 2;
            svg {
                width: rem(25px);
            }
            &.-prev {
                left: rem(-$unit-small);
                svg {
                    transform: rotate(180deg);
                }
            }
            &.-next {
                right: rem(-$unit-small);
            }
            &:hover {
                color: $second-dark;
            }
            &.-disabled {
                // color: $grey;
                opacity: 0.5;
                pointer-events: none;
            }
        }
    }
    .swiper {
        width: 100%;
    }
    // .swiper-wrapper {
    //     justify-content: center;
    // }
}