.c-tab {
  .c-tab_head {
    background-color: $grey-light;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    .c-tab_item {
      padding: rem($unit-tiny) rem($unit-small);
      font-weight: $medium;
      flex: 1;
      text-align: center;
      transition: $speed $easing;
      cursor: pointer;
      &.is-active, &:hover {
        background-color: $white;
      }
    }
  }
  .c-tab_body {
    // display: grid;
    background-color: $white;
    .c-tab_item {
      // grid-column: 1;
      // grid-row: 1;
      display: none;
      &.is-active {
        display: block;
      }
    }
  }
}
