.c-map_container {
    overflow: hidden;
    position: relative;
    .c-map_wrap {
        // height: calc(100vh - #{rem($header_height)});
        height: 100%;
        .c-map_infowindow {
            position: absolute;
            top: 0;
            right: 0;
            width: 33.3%;
            transform: translateX(100%);
            transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: $white;
            height: 100%;
            overflow: auto;
            z-index: 3;
        }
        .c-map_filtres {
            transition: transform 0.6s cubic-bezier(0.645, 0.045, 0.355, 1), opacity 0.6s cubic-bezier(0.215, 0.61, 0.355, 1);
            background-color: $white;
        }
    }
    &.is-open {
        .c-map_wrap {
            .c-map_filtres {
                transform: translateX(-100%);
            }
            .c-map_infowindow {
                transform: translateX(0);
            }
        }
    }
    @media (max-width: $to-medium) {
        padding: 0!important;
        .c-map_wrap {
            .c-map_infowindow {
                width: 100%;
            }
        }
    }

    #info-box {
        position: absolute;
        top: 60px;
        z-index: 2;
        background: white;
        padding: 1rem;
        left: 10px;
        box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
    }
}
.c-map {
    min-height: 350px;
    height: calc(100vh - #{rem($header_height)});
    width: 100%;
    position: relative;
    z-index: 2;
}

.c-map_infowindow_wrap {
    display: flex;
    flex-direction: column;
    height: calc(100vh - #{rem($header_height)});
    overflow: auto;
    .c-map_infowindow_close {
        position: absolute;
        top: rem($unit-tiny);
        right: rem($unit-tiny);
    }
    .c-map_infowinfow_image {
        height: rem(150px);
    }
    .c-map_infowindow_content {
        display: grid;
        grid-gap: 0.5rem;
        .icon {
            color: $primary-dark;
        }
    }
    .c-map_infowindow_contact-item {
        display: grid;
        grid-template-columns: rem(20px) 1fr;
        grid-gap: rem($unit-tiny);
    }
    .c-accordion_header {
        padding: 0 0 0.5rem;
    }
    .c-map_infowindow_hour-row {
        display: grid;
        grid-template-columns: rem(100px) 1fr;
        grid-gap: rem($unit-tiny);
    }
}
.c-map_filter-link {
    opacity: 0.5;
    transition: $speed $easing;
    &:hover, &.is-active {
        opacity: 1;
    }
}
  