.c-alerte {
    position: fixed;
    top: rem($header_height);
    left: 0;
    width: 100%;
    z-index: 8;
    color: $white;
    overflow: hidden;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $primary-dark;
        opacity: 0.83;
    }
    .c-alerte_wrap {
        position: relative;
        z-index: 2;
        display: flex;
        line-height: 1;
        color: $white;
    }

    .c-heading {
        position: relative;
        z-index: 2;
    }

    .c-alerte_anim {
        display : flex;
        flex-shrink: 0;
        align-items: center;
        overflow: hidden;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            width: 1px;
            height: 50%;
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            background-color: $white;
        }
        & > * {
            animation: slide-left 20s linear infinite;
        }
        &:hover {
            & > * {
                animation-play-state: paused;
            }
        }
    }
    // .c-alerte_anim > * {
    //     display: inline-block;
    //     animation: slide-left 20s infinite linear;
    // }

    @keyframes slide-left {
        from {
            transform: translateX(0);
        }
        to {
            transform: translateX(-100%);
        }
    }
    @media (min-width: $from-small) {
        padding: 0 rem(40px);
    } 
    @media (max-width: $to-small) {
        padding: 0 rem(20px);
    }
}