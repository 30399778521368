.b-video-accueil {
    position: relative;
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $grey3;
        opacity: 0.4;
    }
    .o-container {
        position: relative;
        z-index: 2;
    }
    .b-video-accueil_player {
        width: rem(90px);
        height: rem(90px);
        background-color: $white;
        border-radius: 50%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        svg {
            width: rem(30px);
        }
    }
}