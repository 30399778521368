.c-search {
    position: relative;
    .c-search_nav_wrap {
        &::before {
            content: "";
            background-color: $grey-light;
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: calc(25vw + #{rem($unit)});
            height: 100%;
        }
    }
    .c-search_nav {
        .c-search_nav_body {
            .c-search_nav_body_item {
                padding: 0.5rem 0;
                display: grid;
                grid-template-columns: 1fr rem(50px);
                grid-gap: rem($unit-tiny);
                border-bottom: 1px solid $grey2;
                font-weight: $medium;
            }
        }
    }
}