.c-socials-home {
    position: absolute;
    right: rem($unit);
    top: 50%;
    transform: translateY(-50%);
    z-index: 9;
    .c-socials {
        display: flex;
        flex-direction: column;
        grid-gap: 0.5rem;
    }
    @media (max-width: $to-small){
        right: rem($unit-small);
    }
}