.c-footer {
    font-size: $font-size-small;
    .c-footer_link {
        color: $white;
        opacity: 0.6;
        &:hover {
            opacity: 1;
        }
    }
    .c-footer_bottom {
        border-top: 1px solid $grey;
        font-size: $font-size-small;
    }
    .c-footer_links-wrap {
        display: grid;
        justify-content: flex-start;
        grid-gap: 0.5rem;
    }
    .c-socials {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .c-button {
            &.-white {
                color: $grey-extra-dark;
                &:hover {
                    color: $white;
                    &::after {
                        background-color: $grey;
                    }
                }
            }
        }
    }
    .c-footer_nav-wrap {
        position: relative;
        &::before, &::after {
            content: "";
            position: absolute;
            top: 0;
            width: 2px;
            height: 100%;
            background-color: $black;
        }
        &::before {
            left: 0;
        }
        &::after {
            right: rem(-$unit);
        }
    }
    @media (max-width: $to-medium) {
        * {
            text-align: center;
        }
        .c-footer_links-wrap {
            justify-content: center;
        }
    }
    @media (max-width: $to-small) {
        .c-socials {
            align-items: center;
        }
    }
}