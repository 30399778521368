.c-button {
    padding: 0.7rem 1.8rem;
    transition: color $speed $easing;
    cursor: pointer;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 45px;
    font-weight: 600;
    // border: 2px solid transparent;

    .c-button_label {
      z-index: 2;
      position: relative;
    }

    &::after {
      content: "";
      position: absolute;
      z-index: 1;
      width: 100%;
      height: 0;
      bottom: 0;
      left: 0;
      transition: height 500ms $easing;
    }

    .icon {
      margin-left: rem($unit-tiny);
      z-index: 2;
      position: relative;
    }
    svg {
      width: rem(20px);
    }

    &.-primary {
      background-color: $primary;
      color: $white;
      &::after {
        background-color: $primary-dark;
      }
    }

    &.-white {
      background-color: $white;
      color: $primary-dark;
      &::after {
        background-color: $primary-dark;
      }
    }
    &.-second {
      background-color: $second;
      color: $white;
      &::after {
        background-color: $second-dark;
      }
    }
    &.-grey-light {
      background-color: $grey-light2;
      color: $primary-dark;
      &::after {
        background-color: $primary-dark;
      }
    }
    &.-primary-outline {
      border: 2px solid transparent;
      background-color: transparent;
      color: $primary-dark;
      border-color: $primary-dark;
      &::after {
        background-color: $primary-dark;
      }
    }

    &.-white-outline {
      border: 2px solid transparent;
      background-color: transparent;
      color: $white;
      border-color: $white;
      &::after {
        background-color: $white;
      }
    }

    &.-round {
      width: rem(50px);
      height: rem(50px);
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      padding: 0;
      &.-small {
        width: rem(30px);
        height: rem(30px);
        svg {
          width: 1rem;
          max-height: rem(20px);
        }
      }
    }

    &.-disabled {
      pointer-events: none;
      // color: $white;
      // background-color: $grey;
      opacity: 0.5;
    }

    @include u-hocus {
      &::after {
        height: 100%;
      }
      &.-white {
        color: $white;
      }
      &.-grey-light {
        color: $white;
      }
      &.-primary-outline {
        color: $white;
      }
  
      &.-white-outline {
        color: $primary-dark;
      }
    }
}

.c-button-link {
  font-weight: $medium;
  &.-second {
    color: $second;
  }
  .icon {
    margin-left: 0.5rem;
    svg {
      transition: $speed $easing;
    }
  }

  @include u-hocus {
    .icon {
      svg {
        transform: translateX(1rem);
      }
    }
  }
}