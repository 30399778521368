.c-page-header {
    position: relative;
    overflow: hidden;
    &.-bg {
        &::before {
            content: "";
            position: absolute;
            width: 300vw;
            height: 285vw;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: $grey-light;
            z-index: -1;
            border-radius: calc(300vw / 2);
        }
    }
}