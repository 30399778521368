.b-slider {
    .b-slider_slide {
        min-height: rem(665px);
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        // &::before {
        //     content: "";
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     background-color: $grey-overlay;
        //     z-index: 1;
        // }

        .c-slider_media {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
    .b-slider_content {
        position: relative;
        z-index: 2;
    }
    .c-heading{
        text-shadow: 0 5px 10px rgb(0 0 0 / 30%);
    }
}