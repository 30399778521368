.b-etapes {
    .b-etapes_wrap {
        display: grid;
        grid-gap: rem($unit);
        .b-etapes_item {
            .b-etapes_item_head {
                display: grid;
                grid-template-columns: rem(25px) 1fr;
                grid-gap: rem($unit-small);
            }
        }
    }
}