.c-nav {
  position: fixed;
  top: rem($header_height);
  left: 0;
  right: 0;
  z-index: 9;
  //visibility: hidden;
  transform: translateY(calc(-100% - #{rem($header_height)}));
  transition: transform 0.3s $easing;
  height: calc(100vh - #{rem($header_height)});
  .c-nav_bg {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $black-overlay-light;
      z-index: -1;
    }
  }
  .c-nav_wrap {
    background-color: $white;
    // overflow-x: auto;
    max-height: calc(100vh - #{rem($header_height)});
  }
  .c-nav_image-bg {
    transform: translateY(-100%);
    transition: 0.3s $easing;
    transition-delay: 0.7s;
    @media (max-width: $to-small) {
      display: none;
    }
  }
  .o-layout_item {
    max-height: calc(100vh - #{rem($header_height)});
    overflow: auto;
  }
  
  .c-nav_primary {
    // opacity: 0;
    // transform: translateX(-200px);
    // transition: 0.3s $easing;
    // transition-delay: 0.3s;
    .c-nav_primary_link {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      background-color: $primary2;
      color: $white;
      transition: $speed $easing;
      padding: 1.7rem rem($unit-small) 1.7rem rem($unit);
      border-bottom: 1px solid $primary-dark;
      cursor: pointer;
      .c-nav_primary_link_icon {
        margin-left: rem($unit-tiny);
        svg {
          width: rem(15px);
        }
      }
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background-color: $second;
        transition: $speed $easing;
      }
      &:hover, &.is-active {
        background-color: $primary-dark;
        color: $second;
        &::before {
          width: rem(15px);
        }
      }
    }
  }
  // .c-nav_shop {
  //   opacity: 0;
  //   transform: translateY(100%);
  //   transition: 0.3s $easing;
  //   transition-delay: 0.5s;
  //   .icon {
  //     svg {
  //       max-width: 30px;
  //     }
  //   }
  // }

  .c-nav_primary_sub {
    display: none;
    // grid-gap: rem($unit-tiny);
    height: 100%;
    .c-nav_primary_sublink {
      transition: $speed $easing;
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 2px solid $grey-light;
      padding: 1rem 0;
      .c-nav_primary_link_icon {
        margin-left: rem($unit-tiny);
        svg {
          width: rem(8px);
        }
      }
      &:hover, &.is-active {
        color: $second;
      }
      @media (max-width: $to-medium) {
        color: $white;
      }
    }
    &.is-active {
      display: grid;
    }
  }

  .c-nav_subwrap {
    display: grid;
    padding: 1rem 2.5rem;
    .c-nav_primary_sub {
      grid-column: 1;
      grid-row: 1;
    }
  }


  .c-nav_contact {
    display: grid;
    grid-gap: 0.5rem;
  }

  .c-nav_contact_wrap {
    padding: 2.5rem;
  }  

  @media (max-width: $to-medium) {
    .c-nav_wrap {
      overflow: auto;
    }
  }
}

.has-navOpen {
  .c-nav {
    //visibility: visible;
    transform: translateY(0);
    // .c-nav_primary {
    //   opacity: 1;
    //   transform: translateX(0);
    // }
    // .c-nav_shop {
    //   opacity: 1;
    //   transform: translateY(0);
    // }
    .c-nav_image-bg {
      transform: translateY(0);
    }
  }
}
