.b-fichiers {
    .b-fichiers_wrap {
        display: grid;
        grid-gap: rem($unit-tiny);
        .b-fichiers_link {
            display: grid;
            grid-template-columns: rem(50px) 1fr;
            grid-gap: rem($unit-tiny);
            align-items: center;
            color: initial;
            &:hover {
                .c-button {
                    &::after {
                        height: 100%;
                    }
                    &.-second {
                        &::after {
                            background-color: $second-dark;
                        }
                    }
                }
            }
        }
    }
}