.b-chiffres {
    position: relative;
    .deco {
        // position: absolute;
        // top: 0;
        // left: 0;
        // right: 0;
        // bottom: 0;
        // z-index: -1;
        svg {
            width: 100%;
            transform: rotate(180deg);
        }
    }
    .b-chiffres_wrap {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        grid-gap: rem($unit);
    }
    .b-chiffres_item {
        display: flex;
        align-items: center;
    }
}