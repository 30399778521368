.c-heading {
    line-height: $line-height-h;
    margin-bottom: 0;
    font-weight: $medium;
    
    &.-h1 {
        font-size: rem($font-size-h1);
        line-height: $line-height-default;
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h1)} - 10px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h1)} - 15px);
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h2)} - 5px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h2)} - 10px);
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h3)} - 5px);
        }
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h3)} - 10px);
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        @media (max-width: $to-medium) {
          font-size: calc(#{rem($font-size-h4)} - 5px);
        }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        @media (max-width: $to-small) {
          font-size: calc(#{rem($font-size-h5)} - 3px);
        }
    }

    &.-h6 {
        font-size: rem($font-size-h6);
        font-weight: $medium;
    }

    &.-border-top {
      position: relative;
      padding-top: rem($unit-small);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        background-color: $primary;
        width: rem(70px);
        height: rem(5px);
        border-radius: rem(45px);
      }
      &.-border-white {
        &::before {
          background-color: $white;
        }
      }
      &.-border-center {
        &::before {
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}
