.b-galerie {
  .b-galerie_slider_item {
    height: 100%;
    max-height: rem(500px);
  }
  .b-galerie_wrap {
    .b-galerie_grid {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: rem($unit-small);
    }
    .b-galerie_item {
      width: 100%;
      height: 100%;
    }
    .b-galerie_type1 {
      grid-template-columns: 1fr 1fr;
    }
    .b-galerie_type2 {
      grid-template-columns: 1fr 1fr;
    }
    .b-galerie_type3 {
      grid-template-columns: 1fr;
    }
    .b-galerie_type4 {
      grid-template-columns: 1fr 1fr;
    }
    .b-galerie_sub {
      grid-template-columns: repeat(5, 1fr);
    }
  }
}
