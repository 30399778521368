.o-scroll {
    min-height: 100vh;
}

html.has-scroll-smooth {
	backface-visibility: hidden;
	transform: translateZ(0);
}

[data-load-container] {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	width: 100vw;
}