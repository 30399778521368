// ==========================================================================
// Base / Fonts
// ==========================================================================

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Regular"
// ) {
//     font-style:  normal;
//     font-weight: 400;
// }

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Medium"
// ) {
//     font-style:  normal;
//     font-weight: 500;
// }

// @include font-face(
//     $font-foobar,
//     "fonts/Foobar/Semibold"
// ) {
//     font-style:  normal;
//     font-weight: 600;
// }

// @include font-face(
//     $font-bazqux,
//     "fonts/Bazqux/Regular",
//     ("eot", "woff2", "woff", "ttf")
// ) {
//     font-style:  normal;
//     font-weight: 400;
// }

@include font-face(
    $font-poppins,
    "fonts/Poppins/Poppins-Regular",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style:  normal;
    font-weight: 400;
}

@include font-face(
    $font-poppins,
    "fonts/Poppins/Poppins-Medium",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style:  normal;
    font-weight: 500;
}

@include font-face(
    $font-poppins,
    "fonts/Poppins/Poppins-Light",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style:  normal;
    font-weight: 300;
}

@include font-face(
    $font-poppins,
    "fonts/Poppins/Poppins-SemiBold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style:  normal;
    font-weight: 600;
}

@include font-face(
    $font-poppins,
    "fonts/Poppins/Poppins-Bold",
    ("eot", "woff2", "woff", "ttf")
) {
    font-style:  normal;
    font-weight: 700;
}