// ==========================================================================
// Settings / Config / Colors
// ==========================================================================

// Palette
// =============================================================================
$white: #FFFFFF;
$black: #172920;
$primary: #b9d934;
$primary2: #2D8056;
$primary-dark: #195f34;
$second: #fcc11f;
$second-dark: #D38200;

$green: #77C483;
$red: #D1603E;

$grey: #606060;
$grey2: #aeaeae;
$grey3: #555555;
$grey-light: #fafafa;
$grey-light2: #F2F7F4;
$grey-extra-dark: #383838;

$black-overlay-light: rgba(0, 0, 0, 0.5);
$grey-overlay: rgba(90, 90, 90, 0.4);


// Specific
// =============================================================================
// Link
$link-color:       $black;
$link-focus-color: $primary;
$link-hover-color: $primary;
// Selection
$selection-text-color:       $primary;
$selection-background-color: #FFFFFF;

// Social Colors
// =============================================================================
$facebook-color:  #3B5998;
$instagram-color: #E1306C;
$youtube-color:   #CD201F;
$twitter-color:   #1DA1F2;
