.c-share {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    a {
        margin-right: rem($unit-tiny);
        &:last-of-type {
            margin-right: 0;
        }
    }
}