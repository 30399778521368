.c-slider_container {
    &.-offset-small {
        margin-right: rem(-$unit-small);
    }
    &.-offset {
        margin-right: rem(-$unit);
    }

    &.-offset-double {
        margin-right: calc(#{rem(-$unit)} * 2);
    }

    .c-slider_content{
        @media (max-width: 1000px){
        width: 50%;
        }
    }
}
  
.swiper-slide {
    height: auto!important;
}

.swiper-wrapper {
    width: 100%;
}

.c-slider_nav {
    .swiper-navigation {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        svg {
            width: rem(25px);
        }
        .-prev {
            svg {
                transform: rotate(180deg);
            }
        }
        .swiper-navigation_button {
            cursor: pointer;
            color: $second;
            &:hover {
                color: $second-dark;
            }
            &.swiper-button-disabled {
                color: $grey;
                pointer-events: none;
            }
        }
    }
}