.c-breadcrumb {
    text-transform: uppercase;
    font-size: rem(14px);
    font-weight: $medium;
    &.u-c-primary {
        a, span {
            color: $primary;
        }
    }
    &.u-c-white {
        a, span {
            color: $white;
        }
    }
    .pipe {
        margin: 0 0.5rem;
    }
}