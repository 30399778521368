.c-card {
  position: relative;
  &.-page {
    @media (max-width: $to-small) {
      transform: none!important;
    }
    overflow: hidden;
    height: 100%;
    border-radius: rem(10px);
    .o-ratio_content_bg {
      transition: $speed $easing;
    }
    .c-card_wrap {
      position: relative;
      z-index: 2;
      height: 100%;
      padding: rem($unit-small);
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black-overlay-light;
        z-index: 1;
      }
    }
    .c-card_body {
      position: relative;
      z-index: 3;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    .c-card_foot {
      margin-top: auto;
      svg {
        transition: $speed $easing;
        height: rem(20px);
        margin-left: 0.5rem;
      }
    }
    &:hover {
      .o-ratio_content_bg {
        transform: scale(1.05);
      }
      .c-card_foot {
        svg {
          transform: translateX(0.5rem);
        }
      }
    }
  }
  &.-page-list {
    border-top: 1px solid $grey-light;
    &:first-of-type {
      border-top: 2px solid $grey-light;
    }
    .c-card_wrap {
      padding: rem($unit-tiny) 0;
      position: relative;
      transition: $speed $easing;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: $grey-light;
        height: 100%;
        width: 0;
        transition: $speed $easing;
      }
    }
    .c-card_body {
      display: grid;
      grid-template-columns: 1fr rem(20px);
      grid-gap: rem($unit-tiny);
      svg {
        height: rem(25px);
      }
    }
    &:hover {
      .c-card_wrap {
        padding: rem($unit-tiny);
        &::before {
          width: 100%;
        }
      }
    }
  }
  &.-page-list-image {
    border-top: 1px solid $grey-light;
    &:first-of-type {
      border-top: 2px solid $grey-light;
    }
    .c-card_wrap {
      display: grid;
      grid-template-columns: 1fr rem(350px);
    }
    .c-card_body {
      padding: rem($unit-small) 0;
      position: relative;
      display: grid;
      grid-template-columns: rem(15px) 1fr;
      grid-gap: rem($unit-tiny);
      align-items: center;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        background-color: $grey-light;
        opacity: 0.5;
        height: 100%;
        width: 0;
        transition: $speed $easing;
      }
      .icon {
        svg {
          
        }
      }
    }
    .c-card_image {
      position: relative;
      overflow: hidden;
      &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: $black-overlay-light;
        z-index: 1;
      }
      img {
        transition: $speed $easing;
      }
    }
    &:hover {
      .c-card_body {
        &::before {
          width: 100%;
        }
      }
      .c-card_image {
        img {
          transform: scale(1.05);
        }
      }
    }
  }
  &.-actualite {
    img {
      transition: $speed $easing;
    }
    .c-card_image {
      overflow: hidden;
      border-radius: rem(15px);
      height: rem(290px);
    }
    .c-card_date {
      position: relative;
      padding-left: rem($unit-small);
      color: $grey2;
      font-size: rem($font-size-small);
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: rem(20px);
        height: rem(2px);
        background-color: $grey2;
      }
    }
    &:hover {
      img {
        transform: scale(1.05);
      }
      a {
        color: $second;
      }
      .c-button-link {
        .icon {
          svg {
            transform: translateX(1rem);
          }
        }
      }
    }
  }
  &.-agenda {
    background-color: $white;
    border-radius: rem(15px);
    box-shadow: 0 rem(10px) rem(30px) rgba(44, 77, 62, 0.1);
    .c-card_content {
      display: grid;
      grid-template-columns: rem(80px) 1fr;
    }
    .c-card_body {
      border-left: rem(5px) solid $grey-light;
      display: flex;
      flex-direction: column;
      .c-card_foot {
        margin-top: auto;
      }
    }
    .c-card_date {
      display: grid;
      grid-gap: 0.5rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      align-self: center;
      * {
        font-weight: $bold!important;
      }
    }
    &:hover {
      a {
        color: $second;
      }
      .c-button-link {
        .icon {
          svg {
            transform: translateX(1rem);
          }
        }
      }
    }
  }
  &.-post {
    background-color: $grey-light;
    border-bottom: 1px solid $grey2;
    .c-card_date {
      position: relative;
      padding-left: rem($unit-small);
      color: $grey2;
      font-size: rem($font-size-small);
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: rem(20px);
        height: rem(2px);
        background-color: $grey2;
      }
    }
    .c-card_foot {
      display: flex;
      align-items: center;
    }
    .c-card_icon {
      width: rem(47px);
      height: rem(47px);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: rem($unit-small);
      img {
        height: rem(21px);
      }
    }
    &:hover {
      a {
        color: $second;
      }
      &.-primary {
        color: $primary;
      }
      &.-primary-dark {
        color: $primary-dark;
      }
      &.-second {
        color: $second;
      }
      &.-grey {
        color: $grey;
      }
      &.-grey-light {
        color: $grey-light;
      }
      &.-black {
        color: $black;
      }
      &.-second-dark {
        color: $second-dark;
      }
      .c-button-link {
        .icon {
          svg {
            transform: translateX(1rem);
          }
        }
      }
    }
  }
  &.-round {
    text-align: center;
    // max-width: rem(150px);
    a {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .c-card_icon {
      width: rem(130px);
      height: rem(130px);
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $grey-light2;
      background-color: $grey-light2;
      border-radius: 50%;
      transition: $speed $easing;
      img {
        max-width: rem(60px);
        max-height: rem(40px);
      }
    }
    .c-card_title {
      transition: $speed $easing;
    }
    &:hover {
      .c-card_icon {
        border-color: $primary-dark;
        box-shadow: 0 5px 10px rgba(25, 95, 52, 0.2);
      }
      .c-card_title {
        color: $primary-dark;
      }
    }
  }
}
